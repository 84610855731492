<template>
  <div class="member">
    <img :src="imagePath" :alt="name" />
    <Title :title="name.toUpperCase()" :subtitle="role" />
    <p><slot /></p>
  </div>
</template>

<script>
import Title from '@/components/Title.vue'

export default {
  props: ['imagePath', 'name', 'role'],
  components: {
    Title
  }
}
</script>

<style lang="scss" scoped>
@use '@/styles/_presets.scss';

.member {
  display: inline;
  max-width: 90vw;
  text-align: center;

  img {
    @extend .rounded;
  }
}
</style>
