<template>
  <div>
    <Title title="TEAM FLIGHTSCOPE" />
    <div class="team">
      <TeamMember :imagePath="require('@/assets/images/team/rod-flockhart-250.png')" name="Rod Flockhart" role="Chief Flying Instructor">Rod has been a pilot since the late 80s and started his career as a Qantas Aircraft Maintenance Engineer. Rod then worked as a flight instructor and charter pilot before joining Qantaslink flying Dash 8s around Queensland before becoming a training captain with them.</TeamMember>
      <TeamMember :imagePath="require('@/assets/images/team/steve-taddeucci-250.jpg')" name="Steve Taddeucci" role="GA Instructor &amp; Examiner">In 40 years of professional flying with over 20000 hours engaged in charter, aerial advertising, aerial mapping &amp; photographic survey, agricultural flying, instructing and testing. I am happy to join the team here at Flightscope Aviation and help other aspiring aviators reach their goals.</TeamMember>
      <TeamMember :imagePath="require('@/assets/images/team/tony-morris-cropped-250.jpg')" name="Tony Morris" role="GA &amp; RA-Aus Instructor">Tony is a passionate instructor, always looking to encourage and assist students to achieve their best. His instruction combines some fun, whilst also sharing aeronautical theory and aircraft handling, to bring out the highest performance and flight training experience for his students.</TeamMember>
    </div>
    <MoreAbout class="moreabout" />
    <TrainingApproval />
  </div>
</template>

<script>
import Title from '@/components/Title.vue'
import MoreAbout from '@/components/MoreAbout.vue'
import TrainingApproval from '@/components/TrainingApproval.vue'
import TeamMember from '@/components/TeamMember.vue'

export default {
  components: {
    MoreAbout,
    Title,
    TrainingApproval,
    TeamMember
  }
}
</script>

<style lang="scss" scoped>
@use '@/styles/_presets.scss';
@import '@/styles/_colors.scss';

.team {
  display: inline-grid;
  width: 90vw;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 10vh;
  margin: 0 auto 10vh auto;
}
</style>
