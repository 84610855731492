<template>
  <div class="training-approval">
    <Title title="TRAINING APPROVAL" />
    <div class="training-grid">
      <div class="training-grid__left">
        <h1>PART 141 APPROVAL CERTIFICATE</h1>
        <h2>GA Training Approval</h2>
      </div>
      <p class="training-grid__right">This Part 141 Flight Training Approval Certificate was issued by Civil Aviation Safety Authority after a 2 year process of intensive assessment of our flight school's processes, structure and aircraft. It is subject to regular audits and checks. <br><br> Our Part 141 certificate allows us to provide training from Recreational Pilot Licence all the way to Commercial Pilot Licence. Including night VFR ratings and flight training for the purpose of conducting flight reviews.</p>
      <div class="training-grid__left">
        <h1><a href="https://c17517d3-8f33-4a5a-afc5-2874235446bf.filesusr.com/ugd/5c82d3_b7f469e79072430aa481b153278f8a1a.pdf">RA-AUS FLIGHT TRAINING SCHOOL</a></h1>
        <h2>Recreational Aviation Australia</h2>
      </div>
      <p class="training-grid__right">To ensure a consistent and safe experience, RA-Aus registered schools are subject to a vigorous audit and are overseen by senior staff within Recreational Aviation Australia. <br><br> Our school operates a variety of RA-Aus aircraft, which are also required to undergo a rigorous maintenance program to ensure reliability and safety for all our students.</p>
    </div>
  </div>
</template>

<script>
import Title from '@/components/Title.vue'

export default {
  components: {
    Title
  }
}
</script>

<style lang="scss" scoped>
@use '@/styles/_presets.scss';
@import '@/styles/_colors.scss';

.training-approval {
  background-color: $primary;
  color: $secondary;
}

.training-grid {
  display: inline-grid;
  @media screen and (max-width: 600px) { grid-template-columns: 1fr; }
  grid-template-columns: 1fr 1fr;
  align-items: center;

  &__left {
    font-size: 40%;

    a {
      color: $secondary;
      transition: color 0.3s;
      &:hover {
        color: darken($secondary, 20%);
      }
    }

    h2 {
      font-size: 3vh;
    }
  }

  &__right {
    font-size: 2.3vh;
    padding: 4vh;
  }
}
</style>
